<template>
  <kid-trans :pageMode="pageMode"></kid-trans>
</template>



<script>

import KidTrans from "@/views/BankingCenter/KidTrans";

export default {
  name: "Upcoming",
  components: {KidTrans},
  data(){
    return{
      pageMode:'upcoming'

    }
  },
}
</script>

<style scoped>

</style>
